<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <asset-manager v-model="value.src" asset-type="pdf" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MPdfPageForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null
  })
}
</script>
